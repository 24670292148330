import React, { useEffect, useState } from 'react';
import './App.css';
import Music from './resources/hbd.mp3'
function App() {
  const [start, setStart] = useState(false);
  const music = new Audio(Music);
  music.loop = true;
  const playMusic = () => {
    setStart(true)
    music.play();
  }
  return (
    <div style={{backgroundColor: "#F9FCF8"}} className="bg-custom-background bg-fixed bg-cover flex-1 w-screen h-screen">
      <div className="text-3xl font-bold pt-96">
      {start ? (
        <textarea readOnly id="message" rows="15" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 dark:bg-gray-50  dark:placeholder-gray-400 dark:text-gray-600" placeholder="Write your thoughts here...">
      My Dearest Baby!
Happy 2nd anniversary to us! These two years have been the most incredible, joyous journey I could have ever imagined. Every moment spent with you has been a treasure, every conversation a gem, every smile a testament to our wonderful bond.

I remember vividly how it all started - our first conversation, the sparks that flew, the laughter that ensued - and how it swiftly grew into the beautiful relationship we now share. I'm deeply grateful for every memory we've created together, every challenge we've overcome, and every triumph we've shared.

Looking back on these beautiful years, it fills my heart with immeasurable joy, yet at the same time, there is a sense of anticipation. I eagerly look forward to our third, fourth, fifth anniversary, and all the ones that will follow. Our journey has only just begun and there are countless more happy memories waiting around the corner.

The past two years with you have undeniably been the happiest time of my life. I cherish the laughter, the shared dreams, the quiet moments, and even the bittersweet challenges. They have made us stronger, brought us closer, and have shown me the profound depths of your love.

As we turn the corner and step into the new chapters of our lives, I promise to love you, cherish you, and be there for you through thick and thin. I am hopeful and excited for our future, because I know we will face it together, holding hands, and always in each other's hearts.

In your smile, I find my home. In your eyes, I see my world. And in your love, I find my peace. I am profoundly grateful for you, my love.

Happy anniversary, darling. Here's to us, and to many more years of love, laughter, and happiness.

With all my love,
      </textarea>
      ) : (
        <div onClick={playMusic} className='flex h-screen align-middle justify-center mt-28 text-red-800'>Press to Start</div>
      )}
      </div>
    </div>
  );
}

export default App;
